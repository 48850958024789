import axios from "axios";
import store from "./redux/store";
import {addTimezoneInCache, addTokenInCache, addUserInCache,} from "./utils/index";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
    try {
        const response = await axios.post(
            `${API_URL}/admin/login`,
            {
                email: email.toLowerCase(),
                password: password,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        //TODO remove this shouldnt be needed here. Tried refacto, failed.
        addTokenInCache(response.data.token);
        addTimezoneInCache(response.data.timezone);
        addUserInCache(response.data.userUid);

        return response;
    } catch (error) {

        console.log(error);
        throw error;
    }
};

export const getRecentNotificationForUser = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(`${API_URL}/admin/notifications/recent`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateNotificationStatus = async (notificationIds) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        await axios.put(
            `${API_URL}/admin/notifications/read`,
            {notificationIds},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    } catch (error) {
        throw error;
    }
};


export const getVehicleCount = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/vehicles/count`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getUserCounts = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/users/counts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getInvoiceCount = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/invoices/count`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getExternalApiCallsData = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/external-api-count`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUncheckedDocuments = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/identity-documents/unchecked`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const putMarkDocumentAsValid = async (documentId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.put(
            `${API_URL}/admin/identity-documents/${documentId}/valid`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const putMarkDocumentAsInvalid = async (documentId, rejectionReason) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.put(
            `${API_URL}/admin/identity-documents/${documentId}/invalid`,
            {
                rejectionReason: rejectionReason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDocumentMetadata = async (documentId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/identity-documents/${documentId}/metadata`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDocument = async (documentId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/identity-documents/${documentId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getVehicleOwnershipDocument = async (documentId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/vehicle-ownership-requests/${documentId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUncheckedVehicleOwnershipDocuments = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/vehicle-ownership-requests/unchecked`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const markVehicleDocumentAsValid = async (documentId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.put(
            `${API_URL}/admin/vehicle-ownership-requests/${documentId}/valid`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};


export const markVehicleDocumentAsInvalid = async (documentId, rejectionReason) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.put(
            `${API_URL}/admin/vehicle-ownership-requests/${documentId}/invalid`,
            {
                rejectionReason: rejectionReason
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllUsers = async () => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/users`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const incrementUserTokens = async (userId, quantityToAdd) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.put(
            `${API_URL}/admin/tokens/${userId}/increment`,
            {
                "quantity": quantityToAdd,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getUser = async (userId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.get(
            `${API_URL}/admin/users/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}


export const deleteUser = async (userId) => {
    const state = store.getState();
    const token = state.auth.jwt;

    try {
        const response = await axios.delete(
            `${API_URL}/admin/users/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }

};
