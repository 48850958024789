import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Typography} from '@mui/material';
import {getExternalApiCallsData} from "../../../api";
import AverageCallsStats from "./Components/AverageCallsStats";

const ApiCallsPricingStats = () => {
    const [totalAggregatedExternalApiCallsStats, setTotalAggregatedExternalApiCallsStats] = useState([]);
    const [groupedByMethodStats, setGroupedByMethodStats] = useState([]);
    const [totalAggregatedExternalApiCallsPerMethod, setTotalAggregatedExternalApiCallsPerMethod] = useState([]);
    const [averageCallsPerUserPerMethodLastWeek, setAverageCallsPerUserPerMethodLastWeek] = useState([]);
    const [averageCallsPerUserPerMethodLastMonth, setAverageCallsPerUserPerMethodLastMonth] = useState([]);
    const [averageCallsPerUserPerMethodLastTwoDays, setAverageCallsPerUserPerMethodLastTwoDays] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define the pricing calculation function at the component level
    const calculateCost = (methodName, callCount) => {
        if (typeof callCount !== 'number') return { tier1: 0, tier2: 0, tier3: 0, tier4: 0, tier5: 0 };

        // Price mapping based on the provided pricing table
        const pricingTiers = {
            'getVehicleInformation': {
                tier1: 0.15, // Vehicle Data (UK)
                tier2: 0.12,
                tier3: 0.08,
                tier4: 0.06,
                tier5: 0.04
            },
            'getVehicleImage': {
                tier1: 0.02, // Vehicle Images
                tier2: 0.02,
                tier3: 0.01,
                tier4: 0.01,
                tier5: 0.01
            },
            'getMOTHistory': {
                tier1: 0.06, // MOT History
                tier2: 0.05,
                tier3: 0.04,
                tier4: 0.02,
                tier5: 0.02
            },
            'getTyreData': {
                tier1: 0.09, // Tyre Data
                tier2: 0.08,
                tier3: 0.07,
                tier4: 0.06,
                tier5: 0.05
            },
            'getBatteryData': {
                tier1: 0.08, // Battery Data
                tier2: 0.06,
                tier3: 0.05,
                tier4: 0.04,
                tier5: 0.02
            },
            'getUKVDValuation': {
                tier1: 0.20, // UKVD Valuation
                tier2: 0.20,
                tier3: 0.20,
                tier4: 0.20,
                tier5: 0.20
            },
            'getVDICheck': {
                tier1: 2.95, // VDI Check
                tier2: 2.85,
                tier3: 2.75,
                tier4: 2.50,
                tier5: 2.25
            },
            'getSpecAndOptions': {
                tier1: 0.20, // Spec & Options
                tier2: 0.18,
                tier3: 0.16,
                tier4: 0.14,
                tier5: 0.10
            },
            'getPostcodeLookup': {
                tier1: 0.02, // Postcode Lookup
                tier2: 0.02,
                tier3: 0.02,
                tier4: 0.02,
                tier5: 0.02
            },
            'getVehicleDataIRL': {
                tier1: 0.15, // Vehicle Data (IRL)
                tier2: 0.12,
                tier3: 0.08,
                tier4: 0.06,
                tier5: 0.04
            }
        };

        // Default pricing if method not found
        const defaultPricing = {
            tier1: 0.10,
            tier2: 0.08,
            tier3: 0.06,
            tier4: 0.04,
            tier5: 0.02
        };

        const pricing = pricingTiers[methodName] || defaultPricing;

        return {
            tier1: callCount * pricing.tier1,
            tier2: callCount * pricing.tier2,
            tier3: callCount * pricing.tier3,
            tier4: callCount * pricing.tier4,
            tier5: callCount * pricing.tier5
        };
    };

    const groupDataByMethod = (data) => {
        const grouped = {};

        data.forEach(item => {
            const key = `${item.serviceName}-${item.methodName}`;

            if (!grouped[key]) {
                grouped[key] = {
                    serviceName: item.serviceName,
                    methodName: item.methodName,
                    callCount: 0
                };
            }

            grouped[key].callCount += item.callCount;
        });

        return Object.values(grouped);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const externalApiCallData = await getExternalApiCallsData();

                setTotalAggregatedExternalApiCallsStats(externalApiCallData.totalAggregatedExternalApiCallsStats || []);
                setTotalAggregatedExternalApiCallsPerMethod(externalApiCallData.totalAggregatedExternalApiCallsPerMethod || []);
                setAverageCallsPerUserPerMethodLastWeek(externalApiCallData.averageCallsPerUserPerMethodLastWeek || []);
                setAverageCallsPerUserPerMethodLastMonth(externalApiCallData.averageCallsPerUserPerMethodLastMonth || []);
                setAverageCallsPerUserPerMethodLastTwoDays(externalApiCallData.averageCallsPerUserPerMethodLastTwoDays || []);

                // Group data by method name
                if (externalApiCallData.totalAggregatedExternalApiCallsStats) {
                    const grouped = groupDataByMethod(externalApiCallData.totalAggregatedExternalApiCallsStats);
                    setGroupedByMethodStats(grouped);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">An error occurred: {error.message}</Typography>;
    }

    return (
        <>
            <Box>
                <AverageCallsStats
                    weekData={averageCallsPerUserPerMethodLastWeek}
                    monthData={averageCallsPerUserPerMethodLastMonth}
                    lastTwoDays={averageCallsPerUserPerMethodLastTwoDays}
                />

                <Paper elevation={3} style={{margin: '20px', overflowX: 'auto'}}>
                    <Typography variant="h4" style={{textAlign: 'center', margin: '20px 0'}}>API Calls and Pricing Analysis</Typography>
                    <Typography variant="subtitle1" style={{textAlign: 'center', margin: '0 0 15px 0', color: '#666'}}>
                        Grouped by Method Name with Total Cost Comparison Across Pricing Tiers
                    </Typography>
                    <Table aria-label="combined stats table">
                        <TableHead>
                            <TableRow style={{backgroundColor: '#f0f0f0'}}>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Service Name</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Method Name</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Call Count</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Tier 1 (£50 credit)</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Tier 2 (£150 credit)</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Tier 3 (£249 credit)</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Tier 4 (£495 credit)</TableCell>
                                <TableCell style={{fontWeight: 'bold', borderBottom: '2px solid #ddd'}}>Tier 5 (£995 credit)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupedByMethodStats.length > 0 ? (
                                groupedByMethodStats.map((stat, index) => {
                                    const costs = calculateCost(stat.methodName, stat.callCount);
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {stat.serviceName}
                                            </TableCell>
                                            <TableCell>{stat.methodName}</TableCell>
                                            <TableCell>{stat.callCount}</TableCell>
                                            <TableCell>£{costs.tier1.toFixed(2)}</TableCell>
                                            <TableCell>£{costs.tier2.toFixed(2)}</TableCell>
                                            <TableCell>£{costs.tier3.toFixed(2)}</TableCell>
                                            <TableCell>£{costs.tier4.toFixed(2)}</TableCell>
                                            <TableCell>£{costs.tier5.toFixed(2)}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableHead>
                            <TableRow style={{backgroundColor: '#e0e0e0'}}>
                                <TableCell colSpan={2} style={{fontWeight: 'bold'}}>Total</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>{
                                    groupedByMethodStats.reduce((total, stat) => total + stat.callCount, 0)
                                }</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>£{
                                    groupedByMethodStats.reduce((total, stat) => {
                                        const costs = calculateCost(stat.methodName, stat.callCount);
                                        return total + costs.tier1;
                                    }, 0).toFixed(2)
                                }</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>£{
                                    groupedByMethodStats.reduce((total, stat) => {
                                        const costs = calculateCost(stat.methodName, stat.callCount);
                                        return total + costs.tier2;
                                    }, 0).toFixed(2)
                                }</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>£{
                                    groupedByMethodStats.reduce((total, stat) => {
                                        const costs = calculateCost(stat.methodName, stat.callCount);
                                        return total + costs.tier3;
                                    }, 0).toFixed(2)
                                }</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>£{
                                    groupedByMethodStats.reduce((total, stat) => {
                                        const costs = calculateCost(stat.methodName, stat.callCount);
                                        return total + costs.tier4;
                                    }, 0).toFixed(2)
                                }</TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>£{
                                    groupedByMethodStats.reduce((total, stat) => {
                                        const costs = calculateCost(stat.methodName, stat.callCount);
                                        return total + costs.tier5;
                                    }, 0).toFixed(2)
                                }</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Paper>
            </Box>
        </>
    );
};

export default ApiCallsPricingStats;